.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #FEF8F4;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,.1);
  z-index: 20;

  &Container {
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1920px;
  }

  &Wrapper {
    display: flex;
    align-items: center;
    height: 123px;
    padding-left: 13px;
  }

  &Logo {
    height: 115px;
    margin-top: 8px;
  }
}

.menuWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  flex: 1 1 0;
}

.nav {
  display: flex;
  align-items: center;

  &Link {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    color: #8B1026;
    padding: 24px;
    transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      color: #540a17;
    }

    &Dropdown {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      padding: 15px;

      &:hover {
        color: #8B1026;
      }
    }

    &Active {
      background: #f6ede8;
    }

    &Arrow {
      width: 8px;
      height: 8px;
    }
  }

  &Dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 218px;
    background: #f6ede8;
    padding-top: 15px;
    padding-bottom: 18px;
    transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    pointer-events: none;

    &Active {
      opacity: 1;
      pointer-events: all;
    }

    &Wrapper {
      position: relative;
    }

    &Link {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 600;
      display: block;
      padding: 4px 42px 7px 25px;
      font-size: 15px;
      color: #8b1026;
      transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.joinLink {
  font-family: 'Cormorant Garamond';
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-decoration: underline;
  color: #8B1026;
}

.hamburger {
  display: none;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 15px;

  svg {
    width: 20px;
    height: 20px;
    color: #8B1026;
  }
}

.menuOverlay {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
  z-index: 10;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) opacity;

  &Active {
    opacity: 1;
    pointer-events: all;
  }
}

.closeIcon {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header {
    &Wrapper {
      justify-content: center;
    }
  }

  .menuWrapper {
    flex-direction: column;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 350px;
    background: #F6EDE8;
    margin: 0;
    transition: 0.3s;
    z-index: 20;
    transform: translateX(-100%);
  }

  .menuActive {
    transform: translateX(0);
  }

  .nav {
    width: 100%;
    flex-direction: column;
    padding-top: 110px;

    &Link {
      width: 100%;
      padding: 7.5px;
      text-align: center;

      &Arrow {
        display: none;
      }

      &Dropdown {
        position: relative;
        justify-content: center;
      }
    }

    &Dropdown {
      opacity: 1;
      max-height: 0;
      overflow: hidden;
      padding: 0;
      position: unset;
      transition: .3s;
      background: #efdfd6;
      transform: none;
      width: 100%;

      &Active {
        max-height: 230px;
      }

      &Wrapper {
        width: 100%;

        &:last-of-type {
          .navLinkDropdown {
            text-transform: capitalize;
            text-decoration: underline;
          }
        }
      }

      &Link {
        font-size: 18px;
        text-transform: uppercase;
        padding: 7.5px;
        display: block;
        text-align: center;
      }

      &Plus {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        transition: .3s;

        span {
          width: 11px;
          height: 1.5px;
          background: #8B1026;
          transition: .3s;

          &:nth-of-type(2) {
            position: absolute;
            transform: rotate(90deg);
          }
        }

        &Active {
          transform: rotate(90deg);

          span {
            &:nth-of-type(1) {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .closeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 43px;
    left: 14px;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .joinLink {
    display: block;
    padding-bottom: 50px;
  }

  .hamburger {
    display: flex;
  }
}