.wrapper {
  position: relative;
  width: 24px;
  height: 24px;
}
.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  z-index: 5;
}
.dropdown {
  position: absolute;
  top: -13px;
  right: -17px;
  z-index: 6;
  min-width: 220px;
  background: #FFFFFF;
  border: 1px solid rgba(61, 37, 65, 0.2);
  box-shadow: 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 6px;
  padding: 16px 14px;

  &Title {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 135%;
    color: #3D2541;
  }
}