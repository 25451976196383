@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Quattrocento&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 123px;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1320px;
}

.main {
  margin-top: 123px;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.section {
  &Title {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 44px;
    line-height: 48px;
    text-align: center;
    color: #8B1026;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    
    span {
      position: relative;
      padding: 0 134px;

      &::before,
      &::after {
        content: '';
        width: 69px;
        height: 7px;
        border-top: 2px solid #3D2541;
        border-bottom: 1px solid #3D2541;
        position: absolute;
        top: 50%;
      }
  
      &::before {
        left: 0;
      }
  
      &::after {
        right: 0;
      }
    }

  }

  &Subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #311D35;
    margin-top: 32px;
    margin-bottom: 64px;
  }
}

.hero {
  background: #770B1E;
  padding: 130px 0 170px;
  position: relative;

  &Img,
  &Img2 {
    position: absolute;
    top: 28px;
    left: 10px;
  }

  &Img2 {
    left: unset;
    right: 10px;
    transform: scaleX(-1);
  }

  &Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
  }

  &Title {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 44px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 20px;
  }

  &Text {
    margin: 26px 0;
    font-size: 18px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.7);
    max-width: 995px;
  }

  &Btn {
    display: flex;
    justify-content: center;
    width: 308px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 6px;
    line-height: 46px;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 24px;
    color: #92001A;
    transition: .3s;

    &:hover {
      background: #f2f2f2;
    }
  }
}

.doctor {
  position: relative;
  background: #F3E9E8;
  padding: 50px 0;

  &Img {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-100%, -50%);
    z-index: 2;
  }

  &Wrapper {
    width: 100%;
    max-width: 778px;
    min-height: 522px;
    background: #FEF8F4;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 80px 80px 80px 130px;
  }

  &Text {
    font-family: 'Cormorant Garamond';
    font-style: italic;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    color: #311D35;
    margin-bottom: 31px;
    position: relative;
  }

  &Position {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 19px;
    line-height: 135%;
    text-align: right;
    color: #8B1026;
    max-width: 323px;
    margin-left: auto;
    position: relative;

    &::before {
      content: '';
      width: 40px;
      height: 1px;
      background: #8B1026;
      position: absolute;
      top: 12px;
      left: -12px;
      transform: translateX(-100%);
    }
  }

  .quote1 {
    position: absolute;
    top: 0;
    left: 10px;
    transform: translate(-100%, -100%);
  }

  .quote2 {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scaleX(-1);
  }
}

.how {
  padding-bottom: 100px;
  border-bottom: 16px solid #F3E9E8;

  &Wrapper {
    position: relative;
  }

  &ItemWrapper {
    display: flex;
  }

  &Item {
    max-width: 650px;
    margin-left: 50%;
    transform: translateX(-100%);
    padding: 0px 34px 0px 24px;
    margin-bottom: 18px;
    border-left: 8px solid #fff;
    transition: .3s;
    cursor: pointer;
    
    &Active {
      padding: 20px 34px 20px 24px;
      background: #fff;
      border-color: #8B1026;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }

    &Title {
      font-family: 'Cormorant Garamond';
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
      letter-spacing: 0.5px;
      color: #8B1026;
      margin-bottom: 12px;
    }

    &Text {
      font-size: 16px;
      line-height: 26px;
      color: #3D2541;
    }
  }

  &Img {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .3s;
    opacity: 0;

    &Wrapper {
      width: 100%;
      max-width: 660px;
      position: absolute;
      top: 0;
      left: calc(50% + 50px);

      &Active {
        .howImg {
          opacity: 1;
        }
      }
    }
  }
}

.why {
  background: #FAF4F2;
  padding-bottom: 100px;
  border-bottom: 16px solid #F3E9E8;

  &Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }

  &Item {
    width: calc((100% - 60px) / 3);
    background: #FFFFFF;
    min-height: 277px;
    padding: 50px 30px;
    box-shadow: 12px 12px 0 0 rgba(105, 32,40, 0.05);

    &Title {
      font-family: 'Cormorant Garamond';
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.5px;
      color: #8B1026;
      margin-bottom: 18px;
    }

    &Text {
      font-size: 16px;
      line-height: 26px;
      color: #3D2541;
    }
  }
}

.model {
  padding: 120px 0 50px;

  &Wrapper {
    display: flex;
    justify-content: space-between;
    gap: 136px;
  }

  &Img {
    width: 50%;
  }

  &Title {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 44px;
    line-height: 130%;
    color: #92001A;
  }

  &Subtitle {
    font-size: 18px;
    line-height: 135%;
    color: #3D2541;
    margin: 20px 0 34px;
  }

  &List {
    list-style: none;

    &Item {
      position: relative;
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;

        .modelItemArrow {
          display: none;
        }
      }
    }
  }

  &Item {
    &Title {
      font-family: 'Cormorant Garamond';
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
      letter-spacing: 0.5px;
      color: #2D1A30;
      margin-bottom: 12px;
    }

    &Text {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #3D2541;
    }

    &Num {
      width: 68px;
      height: 68px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-size: 46px;
      line-height: 1.3;
      color: #92001A;
      border-radius: 50%;
      text-align: center;
      position: absolute;
      top: -10px;
      left: -22px;
      transform: translateX(-100%);
    }

    &Arrow {
      position: absolute;
      left: -57px;
      top: 75px;
      width: 1px;
      height: calc(100% - 78px);
      background: #92001A;
      border-radius: 1px;

      &Icon {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}

.portal {
  background: #FAF4F2;
  padding: 100px 0;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  &Img {
    width: 50%;
  }

  &Main {
    padding-left: 46px;
  }

  &Title {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 44px;
    line-height: 130%;
    color: #92001A;
  }

  &Text {
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #3D2541;
    margin: 20px 0 50px;
  }

  &List {
    list-style: none;

    &Item {
      position: relative;
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &Item {
    &Title {
      margin-bottom: 12px;
      font-family: 'Cormorant Garamond';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
      letter-spacing: 0.5px;
      color: #2D1A30;
    }

    &Text {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #3D2541;
    }

    &Icon {
      position: absolute;
      top: 0;
      left: -20px;
      transform: translateX(-100%);
    }
  }
}

.results {
  padding-bottom: 100px;
  position: relative;

  &Slide {
    display: flex;
    gap: 25px;

    &Item {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: calc((100% - 25px) / 2);
    }

    &Text{
      font-family: 'Cormorant Garamond';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 135%;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  :global(.swiper-pagination) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin: 70px auto 0;
    height: 14px;
  }

  :global(.swiper-pagination-bullet) {
    display: flex;
    width: 8px;
    height: 8px;
    background: #8B1026;
    border-radius: 50%;
    cursor: pointer;
    transition: .3s;
  }

  :global(.swiper-pagination-bullet-active) {
    width: 14px;
    height: 14px;
    background: transparent;
    border: 3px solid #8B1026;
  }
}

.form {
  background: #FAF4F2;
  padding: 55px 0;

  &Wrapper {
    padding-bottom: 100px;
    background: #FFFFFF;
  }

  &Form {
    max-width: 500px;
    margin: 44px auto 0;
  }

  &Label {
    font-family: 'Quattrocento';
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #3D2541;
  }

  &Input {
    padding: 12px;
    height: 46px;
    border: 0.7px solid rgba(61, 37, 65, 0.4);
    border-radius: 5px;
    font-family: 'Quattrocento';
    font-size: 16px;
    line-height: 135%;
    color: rgba(61, 37, 65, 0.5);
    transition: .3s;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border: 0.7px solid rgba(61, 37, 65, 0.4) !important;
    }
  }

  &Text {
    font-family: 'Quattrocento';
    font-size: 14px;
    line-height: 135%;
    color: rgba(61, 37, 65, 0.7);
    margin-top: 7px;    

    a {
      color: #3D2541;
    }
  }

  &Btn {
    width: 306px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #92001A;
    border-radius: 6px;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 24px;
    line-height: 46px;
    color: #FFFFFF;
    margin: 35px auto;
    transition: .3s;

    &:hover {
      background: #820018;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

@media screen and (max-width: 1440px) {
  .doctor {
    &Img {
      left: 0;
      transform: translate(0, -50%);
      max-width: 50%;
    }

    &Wrapper {
      margin-left: auto;
    }
  }

  .how {
    &Item{
      max-width: 44%;
      transform: none;
      margin-right: auto;
      margin-left: 80px;
    }

    &Img {
      &Wrapper {
        left: unset;
        right: 0;
        max-width: calc(50% - 50px);
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .doctor {
    &Img {
      max-width: 45%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .section {
    &Title {
      font-size: 36px;
      line-height: 135%;
      padding-top: 60px;

      span {
        padding: 0 80px;

        &::before,
        &::after {
          width: 40px;
        }
      }
    }

    &Subtitle {
      font-size: 16px;
      margin: 24px 0 40px;
    }
  }

  .doctor {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    &Img {
      position: unset;
      transform: none;
    }

    &Wrapper {
      margin: 0;
      padding: 80px;
    }
  }

  .how {
    padding-bottom: 60px;

    &Item {
      max-width: 100%;
      margin-left: 0;
      
      &Wrapper {
        flex-direction: column-reverse;
        gap: 20px;
      }
    }

    &Img {
      &Wrapper {
        position: relative;
        top: unset;
        right: unset;
        max-width: 60%;
        padding-bottom: 0;
        margin: 0 auto;
        overflow: hidden;
        transition: .3s;

        &Active {
          padding-bottom: 40%;
        }
      }
    }
  }

  .why {
    padding-bottom: 60px;

    &Item {
      width: calc((100% - 30px) / 2);
    }
  }

  .model {
    padding: 50px 0;

    &Wrapper {
      flex-direction: column;
      gap: 50px;
    }

    &Img {
      margin: 0 auto;
    }

    &Title {
      font-size: 36px;
    }

    &List {
      padding-left: 50px;

      &Item {
        margin-bottom: 20px;
      }
    }

    &Item {
      &Num {
        width: 36px;
        height: 36px;
        font-size: 22px;
        top: 0;
        left: -10px;
      }

      &Arrow {
        left: -30px;
        height: calc(100% - 50px);
        top: 50px;
      }
    }
  }

  .portal {
    padding: 50px 0;

    &Wrapper {
      flex-direction: column-reverse;
      gap: 0;
    }

    &Title {
      font-size: 36px;
    }
  }
}

@media screen and (max-width: 768px) {
  .doctor {
    padding: 20px;
    gap: 20px;

    &Img {
      max-width: 80%;
    }

    &Wrapper {
      padding: 60px;
      min-height: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .hero {
    padding: 40px 0;

    svg {
      width: 80px;
      height: auto;
    }

    &Img,
    &Img2 {
      width: 169px;
      top: 14px;
      left: 14px;
    }

    &Img2 {
      left: unset;
      right: 14px;
    }

    &Title {
      margin-top: 16px;
      font-size: 24px;
      line-height: 130%;
    }

    &Text {
      margin: 10px 0 24px;
      font-size: 14px;
      line-height: 140%;
    }

    &Btn {
      width: 244px;
      height: 42px;
      line-height: 42px;
      font-size: 22px;
    }
  }

  .doctor {
    padding: 20px 0;

    &Img {
      max-width: 100%;
    }

    &Wrapper {
      padding: 35px 20px 20px;
      margin: 0 20px;
      width: auto;
    }

    &Text {
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 15px;
    }

    &Position {
      max-width: 236px;
      font-size: 14px;
      line-height: 135%;

      &::before {
        width: 23px;
        top: 8px;
        left: -6px;
      }
    }

    .quote1,
    .quote2 {
      width: 22px;
    }
  }

  .section {
    &Title {
      font-size: 24px;
      line-height: 32px;
      padding-top: 40px;

      span {
        padding: 0 42px;

        &::before,
        &::after {
          width: 34px;
        }
      }
    }

    &Subtitle {
      font-size: 14px;
      margin: 14px 0 40px;
    }
  }

  .how {
    padding-bottom: 30px;

    &ImgWrapper {
      max-width: 100%;

      &Active {
        padding-bottom: 70%;
      }
    }

    &Item {
      padding: 0px 20px 0px 16px;
      
      &Active {
        padding: 20px 20px 20px 16px;
      }

      &Title {
        font-size: 22px;
        line-height: 26px;
      }

      &Text {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .why {
    padding-bottom: 55px;

    &Item {
      width: 100%;
      padding: 24px;
      min-height: unset;
      box-shadow: 10px 10px 0 0 rgba(105, 32,40, 0.05);

      &Title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      &Text {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  .model {
    padding: 20px 0 40px;

    &Wrapper {
      gap: 20px;
    }

    &Img {
      width: 100%;
    }

    &Title {
      font-size: 22px;
      line-height: 130%;
    }

    &Subtitle {
      margin: 10px 0 13px;
      font-size: 14px;
      line-height: 135%;
    }

    &Item {
      &Title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      &Text {
        font-size: 14px;
        line-height: 26px;
      }
    }
  }

  .portal {
    padding: 40px 0;

    &Img {
      width: 100%;
    }

    &Main {
      padding-left: 0;
    }

    &Title {
      font-size: 22px;
    }

    &Text {
      margin: 10px 0 20px;
      font-size: 14px;
    }

    &List {
      padding-left: 32px;

      &Item {
        margin-bottom: 20px;
      }
    }

    &Item {
      &Title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      &Text {
        font-size: 14px;
        line-height: 26px;
      }

      &Icon {
        width: 22px;
        height: 22px;
        left: -10px;
        top: 2px;
      }
    }
  }

  .results {
    padding-bottom: 52px;

    &Slide {
      flex-direction: column;
      gap: 20px;

      &Item {
        width: 100%;
        gap: 12px;
      }
    }

    :global(.swiper-pagination) {
      margin-top: 30px;
    }
  }

  .form {
    padding: 0;

    &Wrapper {
      margin: 0 -20px;
      padding: 0 20px 45px;
    }

    &Form {
      margin-top: 20px;
      max-width: 100%;
    }

    &Btn {
      margin: 20px auto 16px;
    }

    &Text {
      font-size: 12px;
    }
  }
}