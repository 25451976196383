@font-face {
	font-family: 'CormorantGaramond-Bold';
	src: url('../../../assets/fonts/CormorantGaramond-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'CormorantGaramond-Regular';
	src: url('../../../assets/fonts/CormorantGaramond-Regular.ttf');
	font-weight: 400;
	font-style: normal;
}
