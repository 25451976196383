.phone-input-wrapper {
  background: inherit !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
  border-color: rgba(61, 37, 65, 0.4) !important;

  &:hover, &:focus {
    .selected-flag {
      background: transparent !important;
    }
  }
  .selected-flag {
    background: transparent !important;
  }

  .phone-dropdown-dark {
    background-color: var(--chakra-colors-gray-800);

    li:hover,
    li.highlight {
      background: var(--chakra-colors-gray-700) !important;
    }
  }
}
.phone-input-dropdown {
  width: 346px !important;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  border: none !important;
  
  li {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
    width: 100%;
  
    & > * {
      margin: 0 !important;
    }
  }
}