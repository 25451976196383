.footer {
  background: #8B1026;
  padding: 30px 0 60px;
  color: #fff;

  &Wrapper {
    display: flex;
    justify-content: space-between;
  }

  &Title {
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
  }

  &SocialWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    margin-top: 5px;
  }

  &SocialLinks {
    display: flex;
    gap: 10px;

    svg {
      min-width: 18px;
      height: auto;
    }
  }

  &Logo {
    path {
      fill: rgba(255,255,255,0.6);
    }
  }

  &Links {
    display: flex;
    gap: 94px;
    font-family: 'Cormorant Garamond';
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  &Link {
    text-decoration: underline;
  }

  &Form {
    text-align: right;

    &Title {
      font-family: 'Cormorant Garamond';
      font-weight: 600;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 0.5px;
    }

    &Text {
      margin: 6px 0 14px;
      font-family: 'Cormorant Garamond';
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
    }
  }

  &Input {
    border: 0.5px solid rgba(255,255,255,0.5);
    border-radius: 5px;
    height: 36px;
    width: 272px;
    padding: 10px 12px;
    font-family: 'Cormorant Garamond';
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: rgba(255,255,255,0.5);
    background: transparent;
    outline: none;

    &Wrapper {
      position: relative;
    }

    &Btn {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
    }
  }

  &Btns {
    display: flex;
    gap: 12px;
    margin-top: 16px;
  }

  &Btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #FFFFFF;
    border-radius: 6px;
    flex: 1;
    transition: .3s;

    &:hover {
      background: #f2f2f2;
    }
  }
}

@media screen and (max-width: 830px) {
  .footer {
    &Wrapper {
      flex-direction: column;
      align-items: center;
      gap: 38px;
      text-align: center;
    }

    &Form {
      text-align: center;
    }

    &SocialLinks {
      gap: 25px;
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    &Links {
      flex-direction: column;
      gap: 13px;
    }
  }
}