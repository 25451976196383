@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap");

.lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
.lst-kix_list_3-0 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
  content: "o  ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
.lst-kix_list_5-0 > li {
  counter-increment: lst-ctn-kix_list_5-0;
}
ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "o  ";
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.lst-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_3-7 > li:before {
  content: "o  ";
}
ol.lst-kix_list_5-0.start {
  counter-reset: lst-ctn-kix_list_5-0 0;
}
ol.lst-kix_list_2-2 {
  list-style-type: none;
}
ol.lst-kix_list_2-3 {
  list-style-type: none;
}
.lst-kix_list_5-0 > li:before {
  content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
}
ol.lst-kix_list_2-4 {
  list-style-type: none;
}
ol.lst-kix_list_2-5 {
  list-style-type: none;
}
ol.lst-kix_list_2-0 {
  list-style-type: none;
}
ol.lst-kix_list_2-1 {
  list-style-type: none;
}
.lst-kix_list_4-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_5-3 > li:before {
  content: "\002022  ";
}
.lst-kix_list_4-7 > li:before {
  content: "o  ";
}
.lst-kix_list_5-2 > li:before {
  content: "\002022  ";
}
.lst-kix_list_5-1 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
.lst-kix_list_5-7 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
  content: "\002022  ";
}
.lst-kix_list_5-8 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_4-0 {
  list-style-type: none;
}
ul.lst-kix_list_4-1 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: "\002022  ";
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
  content: "\002022  ";
}
ol.lst-kix_list_2-6 {
  list-style-type: none;
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ol.lst-kix_list_2-7 {
  list-style-type: none;
}
ul.lst-kix_list_4-2 {
  list-style-type: none;
}
ol.lst-kix_list_2-8 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
li.li-bullet-2:before {
  margin-left: -18.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18.1pt;
}
.lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
.lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
ol.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
}
.lst-kix_list_2-7 > li:before {
  content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
}
.lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.lst-kix_list_2-4 > li:before {
  content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
}
.lst-kix_list_2-5 > li:before {
  content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
}
.lst-kix_list_2-8 > li:before {
  content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
li.li-bullet-1:before {
  margin-left: -18.1pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18.1pt;
}
.lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
.lst-kix_list_4-1 > li:before {
  content: "o  ";
}
.lst-kix_list_4-4 > li:before {
  content: "o  ";
}
ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
.lst-kix_list_4-3 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_4-5 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_4-2 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_4-6 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
.lst-kix_list_1-0 > li:before {
  content: "\002022  ";
}
.lst-kix_list_1-1 > li:before {
  content: "\002022  ";
}
.lst-kix_list_1-2 > li:before {
  content: "\002022  ";
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
.lst-kix_list_1-3 > li:before {
  content: "\002022  ";
}
.lst-kix_list_1-4 > li:before {
  content: "\002022  ";
}
.lst-kix_list_1-7 > li:before {
  content: "\002022  ";
}
ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.lst-kix_list_1-5 > li:before {
  content: "\002022  ";
}
.lst-kix_list_1-6 > li:before {
  content: "\002022  ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_2-0 > li:before {
  content: "(" counter(lst-ctn-kix_list_2-0, decimal) ") ";
}
.lst-kix_list_2-1 > li:before {
  content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
}
ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.lst-kix_list_1-8 > li:before {
  content: "\002022  ";
}
.lst-kix_list_2-2 > li:before {
  content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
}
.lst-kix_list_2-3 > li:before {
  content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
}
.agreement ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c0 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: "Calibri";
  font-style: normal;
}
.c3 {
  margin-left: 24pt;
  padding-top: 0.7pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
}
.c32 {
  margin-left: 23.9pt;
  padding-top: 9.8pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
}
.c26 {
  margin-left: 23.9pt;
  padding-top: 0.7pt;
  padding-left: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
}
.c40 {
  margin-left: 23.9pt;
  padding-top: 9.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
  height: 11pt;
}
.c17 {
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
  height: 11pt;
}
.c1 {
  margin-left: 6pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c18 {
  margin-left: 119.7pt;
  padding-top: 1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c12 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}
.c24 {
  margin-left: 6pt;
  padding-top: 9.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
}
.c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c41 {
  padding-top: 1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c35 {
  padding-top: 0.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  margin-right: 5.3pt;
}
.c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.06;
  text-align: center;
  height: 11pt;
}
.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c10 {
  margin-left: 6pt;
  padding-top: 4.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
}
.c13 {
  padding-top: 4.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}
.c28 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.2166666666666666;
  text-align: justify;
  height: 12pt;
}
.c4 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  margin-right: 18pt;
}
.c9 {
  padding-top: 0.3pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c39 {
  padding-top: 0.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
  height: 11pt;
}
.c20 {
  margin-left: 6pt;
  padding-top: 2.8pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: right;
  height: 11pt;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 0.06;
  text-align: center;
}
.c7 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c36 {
  padding-top: 1.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
}
.c33 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
}
.c16 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.2166666666666666;
  text-align: center;
}
.c29 {
  -webkit-text-decoration-skip: none;
  color: #0000ff;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.c22 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
}
.c6 {
  font-size: 12pt;
  font-family: "Calibri";
  font-weight: 700;
}
.c43 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 0 0 20pt 0;
}
.c2 {
  font-weight: 400;
  font-family: "Calibri";
}
.product {
  font-weight: bold;
}
.c15 {
  color: inherit;
  text-decoration: inherit;
}
.c38 {
  padding: 0;
  margin: 0;
}
.c8 {
  font-size: 12pt;
}
.c30 {
  font-size: 10pt;
}
.c37 {
  font-size: 11pt;
}
.c42 {
  height: 11pt;
}
.c31 {
  margin-left: 6pt;
}
.c21 {
  font-size: 8pt;
}
.c34 {
  color: #000000;
}
.c27 {
  font-style: italic;
}
/* .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
} */
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
.agreement li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}
.agreement p {
  /* margin: 0; */
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}
.agreement h1 {
  padding-top: 0pt;
  color: #000000;
  font-size: 14pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  text-align: justify;
}
.agreement h2 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  text-align: left;
}
.agreement h3 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  text-align: left;
}
.agreement h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.agreement h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.agreement h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
.name {
  width: 250px;
}
.degree {
  width: 250px;
}
.company-name {
  width: 200px;
}
.adress {
  width: 250px;
}
.city {
  width: 250px;
}
.country {
  width: 250px;
}
.postcode {
  width: 250px;
}
.phone {
  width: 250px;
}
.fax {
  width: 250px;
}
.email {
  width: 250px;
}
.payment {
  text-align: center;
}
.bank-account {
  width: 175px;
}
.sort-code {
  width: 250px;
}
.distributor {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.name-sign {
  width: 250px;
}
.signed-sign {
  width: 400px;
  outline: none;
  border: none;
  margin-bottom: 20px;
  height: 100px;
}
.date-sign {
  width: 400px;
  outline: none;
  border: none;
}
.bottom-container {
  margin-top: 50px;
}

.agreement input {
  margin: 0px 16px;
  border: 1px solid silver;
  border-radius: 4px;
}

.footer {
  clear: both;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.bottom-container p {
  margin-top: 20px;
}
.spinner-loader {
  text-align: center;
  margin-top: 25%;
}
.label-avatar {
  cursor: pointer;
}

.upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.confirm-email {
  text-align: center;
  margin-top: 15%;
  /* background-color: white; */
}

.title {
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #92001a;
  margin: 0;
  padding: 0;
}

.descr {
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: #3d2541;
}
.descr-margin {
  margin-bottom: 20px;
}

.error {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: #3d2541;
  opacity: 0.5;
  margin: 250px auto 0;
}

.tbody .tr {
  border: 1px solid rgba(61, 37, 65, 0.2);
  box-shadow: 0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 6px;
  margin-bottom: 14px;
}
.tr-hovered {
  cursor: pointer;
}
.tr-with-select {
  position: relative;
  margin-left: 44px;
}
.tr-with-select > .th:first-of-type,
.tr-with-select > .td:first-of-type {
  width: 0 !important;
  flex: 0 0 0 !important;
  padding: 0 !important;
}
.table-with-action .tr > .th:last-of-type {
  width: 95px !important;
  flex: 0 0 95px !important;
  padding: 12px 16px !important;
  overflow: visible;
}
.table-with-action .tr > .td:last-of-type {
  width: 56px !important;
  flex: 0 0 56px !important;
  padding: 12px 16px !important;
  overflow: visible;
  margin-left: 38px;
}
.th {
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  gap: 3px;
}
.td {
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  padding: 14px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-sort {
  font-size: 10px;
  width: 10px;
}
.table-select {
  position: absolute;
  top: 50%;
  left: -44px;
  transform: translateY(-50%);
}
.sort-arrows {
  display: flex;
  width: 10px;
  height: 20px;
  position: relative;
}
.sort-arrows span:first-of-type {
  position: absolute;
  transform: translateY(-5px);
}
.sort-arrows span:last-of-type {
  position: absolute;
  transform: translateY(5px);
}
.back-link {
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  color: #3d2541;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: absolute;
  top: 106px;
  left: 46px;
}

.hr {
  width: 100%;
  height: 1px;
  background: rgba(61, 37, 65, 0.2);
  margin-top: 20px;
  margin-bottom: 30px;
  border: none;
}

.custom-btn {
  min-width: 328px;
  height: 49px;
  background: #92001a;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 49px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #fff8f4;
  transition: 0.15s;
}
.custom-btn:hover {
  background: #750117;
}
.custom-btn:disabled {
  cursor: not-allowed;
  background: rgba(166, 166, 166, 0.6);
}

.outlined-btn {
  height: 49px;
  background: transparent;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #92001a;
  border: 1px solid #92001a;
  padding: 15px 18px;
  cursor: pointer;
}
.outlined-btn:disabled {
  cursor: default;
  border-color: rgba(166, 166, 166, 0.6);
  color: rgba(166, 166, 166, 0.6);
}

.custom-radio,
.custom-checkbox {
  cursor: pointer;
  position: relative;
}
.custom-radio > input,
.custom-checkbox > input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.custom-radio > input:disabled + span,
.custom-checkbox > input:disabled + span {
  border-color: #92001a;
  cursor: not-allowed;
}
.custom-checkbox > input:indeterminate:disabled + span span {
  background: rgba(61, 37, 65, 0.2);
}
.custom-checkbox > input:disabled + span svg path {
  stroke: #92001a;
}
.custom-radio > input:disabled + span::before {
  background: rgba(61, 37, 65, 0.2);
}
.custom-checkbox {
  display: flex;
  width: 22px;
  height: 22px;
}
.custom-checkbox > span {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #92001a;
  border-radius: 3px;
}
.custom-checkbox > span svg,
.custom-checkbox > span span {
  transition: 0.15s;
  opacity: 0;
}
.custom-checkbox > input:checked + span svg {
  opacity: 1;
}
.custom-checkbox > input:indeterminate + span span {
  opacity: 1;
}
.custom-checkbox > span span {
  display: flex;
  width: 10px;
  height: 2px;
  background: #92001a;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-checkbox > span span:last-of-type {
  transform: translate(-50%, -50%) rotate(90deg);
}

.custom-radio > span {
  display: flex;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #92001a;
  border-radius: 50%;
  cursor: pointer;
}
.custom-radio > span::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #92001a;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.15;
}
.custom-radio > input:checked + span::before {
  opacity: 1;
}

.custom-input-wrapper {
  position: relative;
  margin: 30px 0 36px;
  width: 100%;
  max-width: 400px;
}
.custom-input {
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(61, 37, 65, 0.2);
  box-shadow: 0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 6px;
  padding: 17px 17px 17px 52px;
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
  color: rgb(61, 37, 65, 0.7);
  outline: none;
}
.custom-input-date {
  padding: 5px 10px;
}
.custom-input-wrapper svg {
  position: absolute;
  top: 50%;
  left: 19px;
  transform: translateY(-50%);
}

.custom-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 1.5px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #92001a;
  cursor: pointer;
}

.custom-link-disabled {
  cursor: not-allowed;
  opacity: .8;
}

.assessment-add {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-bottom: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.assessment-add svg {
  width: 32px;
  height: 32px;
}

.products-table-wrapper {
  border: 1px solid rgba(61, 37, 65, 0.2);
  border-radius: 6px;
  overflow: hidden;
  margin-top: 20px;
}
.products-table {
  border-collapse: collapse;
  width: 101%;
  margin: -1px;
}
.products-table th,
.products-table td {
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  padding: 14px 42px;
  text-align: left;
  border: 1px solid rgba(61, 37, 65, 0.2);
  text-align: center;
}
.products-table td:last-of-type,
.products-table th:last-of-type {
  background: rgba(254, 248, 244, 0.6);
  text-align: left;
}
.sidebar-title,
.products-table-title {
  position: relative;
}
.sidebar-title span,
.products-table-title > span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  font-weight: 500;
  font-size: 15px;
  line-height: 135%;
  color: #150c16;
  padding: 6px 20px;
  background: #fef8f4;
  border: 1px solid rgba(61, 37, 65, 0.2);
  box-shadow: 0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 6px;
  transition: opacity 0.15s;
  opacity: 0;
  z-index: -1;
}
.sidebar-title span {
  left: 2px;
  transform: translate(-200%, -100%);
}
.sidebar-title span::before,
.products-table-title > span::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7.5px 0 7.5px;
  border-color: #d8d3d9 transparent transparent transparent;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
.sidebar-title span::before {
  left: 14px;
  transform: translate(0, 100%);
}
.sidebar-title span::after,
.products-table-title > span::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6.5px 0 6.5px;
  border-color: #fef8f4 transparent transparent transparent;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% - 0.5px));
}
.sidebar-title span::after {
  left: 15px;
  transform: translate(0, calc(100% - 0.5px));
}
.products-table-title:hover > span {
  opacity: 1;
  z-index: 1;
}
.sidebar-title:hover span {
  opacity: 1;
  z-index: 1;
  transform: translate(0, -100%);
}

.dropdown-item {
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
  color: rgba(61, 37, 65, 0.6);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
  white-space: nowrap;
}
.dropdown-item:last-child {
  margin-bottom: 0;
}

.assessment-field {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.assessment-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  width: 195px;
  min-width: 195px;
  margin-right: 20px;
}

.assessment-variants {
  display: flex;
  flex-wrap: wrap;
}

.assessment-label {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  margin-right: 12px;
  cursor: pointer;
  margin-bottom: 10px;
}
.assessment-wrapper {
  padding-bottom: 110px;
}
.assessment-wrapper-view {
  padding-bottom: 0;
}
.assessment-btn {
  position: absolute;
  bottom: 111px;
  left: 46px;
  right: 46px;
}

.answers {
  background: #ffffff;
  border: 0.5px solid rgba(61, 37, 65, 0.4);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 12px;
  max-height: 888px;
  overflow: auto;
  margin-top: 30px;
}
/* width */
.answers::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.answers::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.answers::-webkit-scrollbar-thumb {
  background: #b1a4ac;
  border: 5px solid #fff;
  border-radius: 14px;
}

/* Handle on hover */
.answers::-webkit-scrollbar-thumb:hover {
  background: #897f85;
}

.singleAnswer img {
  max-width: 400px;
}

.af-tabs {
  display: inline-flex;
  border: 0.6px solid #3d2541;
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 20px;
  width: max-content;
}
.af-tab {
  padding: 4px 12px;
  font-size: 26px;
  line-height: 31px;
  color: rgba(61, 37, 65, 0.7);
  cursor: pointer;
  transition: 0.15s;
}

.af-tab-active {
  color: #92001a;
  background: #f5efea;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.download-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px 20px;
  background: #ffffff;
  border: 1px solid rgba(61, 37, 65, 0.2);
  border-radius: 6px;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  margin-bottom: 14px;
  /* width: 440px; */
}

.download-item-user {
  justify-content: flex-start;
  gap: 25px;
}

.download-item-id {
  font-weight: 500;
}

.download-item-user > *:last-child {
  margin-left: auto;
}

.download-item span {
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration-line: underline;
  color: #3d2541;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.dashboard-card {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px;
}
.dashboard-center-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  padding: 0 !important;
}
.dashboard-circle {
  width: 305px;
  height: 305px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-width: 45px;
  border-style: solid;
  transform: rotate(45deg);
  position: relative;
}
.dashboard-circle::before {
  content: "";
  display: flex;
  width: 310px;
  height: 3px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 1;
}
.dashboard-circle::after {
  content: "";
  display: flex;
  width: 3px;
  height: 310px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 1;
}
.dashboard-circle-border {
  display: flex;
  width: 194px;
  height: 194px;
  border-radius: 50%;
  background: #f8f8f8;
  position: relative;
  z-index: 3;
}
.dashboard-circle-border::before {
  content: "";
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 204px;
  height: 204px;
  border: 2px dotted #dddddd;
  border-radius: 50%;
}
.dashboard-circle-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3d2541;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 4;
}
.dashboard-circle-title span {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #3d2541;
}
.dashboard-circle-5,
.dashboard-circle-10,
.dashboard-circle-15,
.dashboard-circle-20 {
  position: absolute;
  z-index: 4;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  transform: rotate(-45deg);
}
.dashboard-circle-5 {
  top: -32px;
}
.dashboard-circle-10 {
  right: -40px;
}
.dashboard-circle-15 {
  bottom: -32px;
}
.dashboard-circle-20 {
  left: -40px;
}

.switch {
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  display: flex;
  align-items: center;
  gap: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #92001a;
}
.switch input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
.switch input:checked + span {
  border-color: #92001a;
}
.switch input:checked + span::before {
  background: #92001a;
  transform: translate(12.5px, -50%);
}
.switch input:disabled + span {
  border-color: rgba(61, 37, 65, 0.2);
  cursor: not-allowed;
}
.switch input:disabled + span::before {
  background: rgba(61, 37, 65, 0.2);
}
.switch span {
  position: relative;
  display: flex;
  width: 32px;
  height: 20px;
  border: 2px solid rgba(61, 37, 65, 0.2);
  border-radius: 12px;
  cursor: pointer;
  transition: 0.15s;
}
.switch span::before {
  content: "";
  display: flex;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgba(61, 37, 65, 0.2);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(1.5px, -50%);
  transition: 0.15s;
}

.upload-avatar {
  position: relative;
  cursor: pointer;
}
.upload-avatar img {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  border: 1px solid silver;
}
.upload-avatar-icon {
  position: absolute;
  right: -4px;
  bottom: -2px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 50%;
}
.upload-avatar > input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.header-profile {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #3d2541;
}

.header-profile img {
  border: 1px solid silver;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  box-shadow: 0px 8px 40px -2px rgba(6, 12, 59, 0.08);
}

.chakra-alert__title {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 135% !important;
}

.header-line {
  border: 1px solid black;
}

.terms-content {
  padding: 10%;
  align-items: center;
}

.header-terms {
  text-align: center;
  display: flex;
  justify-content: center;
}

.terms-logo {
  display: flex;
  justify-content: center;
}

.header-title {
  color: rgb(139, 16, 38);
  font-size: 32px;
  margin-bottom: 10px;
  align-self: center;
  text-align: center;
  font-weight: bold;
}

.document-subtitle {
  color: rgb(139, 16, 38);
  font-size: 28px;
  margin: 10px 0 10px 0;
  /* margin-bottom: 10px; */
  align-self: center;
  font-weight: bold;
}

.account-terms,
.content-terms,
.user-terms,
.user-content-terms,
.we-terms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.terms-descriptions {
  width: 500px;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 32px;
}

.pagination-page {
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 1.5px;
  color: #3d2541;
  cursor: pointer;
  transition: 0.15s;
}

.pagination-page-active {
  opacity: 0.6;
}

.pagination-next {
  cursor: pointer;
}

.switched-user {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  height: 30px;
  z-index: 5;
  text-align: center;
  line-height: 30px;
  background: #fff;
  box-shadow: 2px 0px 10px 0px rgb(0 0 0 / 10%);
}

.payments-title {
  font-size: 26px;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .switched-user {
    left: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .assessment-btn {
    bottom: 140px;
  }
}

.mark-as-pay {
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  color: #3d2541;
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.expert-btn {
  min-width: 150px;
}

.experts-products-label {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  margin-bottom: 20px;
  cursor: pointer;
}
.experts-products-table {
  border: 1px solid rgba(61, 37, 65, 0.2);
  border-radius: 6px;
}
.experts-products-table-head {
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  padding: 14px 24px;
}
.experts-products-table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(254, 248, 244, 0.6);
  padding: 14px 64px 14px 24px;
  font-weight: 500;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  border-top: 1px solid rgba(61, 37, 65, 0.2);
  position: relative;
}
.experts-products-table-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  transition: 0.3s;
}
.experts-products-table-icon.active {
  transform: translateY(-50%) rotate(180deg);
}
.experts-products-table-accordion {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}
.experts-products-table-accordion.active {
  max-height: 250px;
}
.experts-products-table-accordion .experts-products-table-row {
  cursor: pointer;
}
.experts-tabs {
  margin: 0;
}
.experts-tabs .af-tab {
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  cursor: default;
}

.individual-products-table-scroll {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  border: 1px solid rgba(61, 37, 65, 0.2);
  border-radius: 6px;
  margin-bottom: 30px;
}
/* width */
.individual-products-table-scroll::-webkit-scrollbar {
  width: 18px;
}
/* Track */
.individual-products-table-scroll::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}
/* Handle */
.individual-products-table-scroll::-webkit-scrollbar-thumb {
  background: #d7d0d3;
  border-radius: 10px;
  width: 10px;
  border: 4px solid #fff;
}
/* Handle on hover */
.individual-products-table-scroll::-webkit-scrollbar-thumb:hover {
  background: #b9b3b6;
}
.individual-products-table {
  width: 100%;
}
.individual-products-table-th,
.individual-products-table-td {
  padding: 14px 28px;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  border-right: 1px solid rgba(61, 37, 65, 0.2);
}
.individual-products-table-th {
  font-weight: 600;
}
.individual-products-table-td {
  font-weight: 400;
}
.individual-products-table-tr {
  border-bottom: 1px solid rgba(61, 37, 65, 0.2);
}
.individual-products-table-body .individual-products-table-tr:last-of-type {
  border-bottom: none;
}
.individual-products-table-td-accent {
  background: rgba(254, 248, 244, 0.6);
  margin: -14px -28px;
  padding: 14px 28px;
  display: flex;
}

.new-order-price {
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  display: flex;
  align-items: center;
  color: rgba(61, 37, 65, 0.7);
}
.new-order-price svg {
  margin-right: 8px;
}
.new-order-price span {
  font-weight: 600;
  color: #3d2541;
  margin-left: 15px;
}

.counter {
  position: relative;
}
.counter-input {
  width: 130px;
  height: 41px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  line-height: 135%;
  color: #3d2541;
  text-align: center;
}
.counter-input:focus {
  outline: none;
}
.counter-increment,
.counter-decrement {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.counter-increment {
  right: 16px;
}
.counter-decrement {
  left: 16px;
}
.error-details {
  color: silver;
  font-size: 75%;
  margin-top: 20px;
}
.shipment-subtitle {
  padding-top: 8px;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}
.shipment-preview-subtitle {
  font-size: 18px;
  color: #666666;
  padding-bottom: 8px;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}

.shipment-create-date-input {
  padding: 5px 10px;
  width: 440px;
  height: 44px;
  border-color: #3d2541;
}

.order-view-info-error {
  margin: 0 auto;
}

.order-view-package-status-ready {
  color: #219653;
}

.order-view-package-status-waiting {
  color: #df710c;
}

.notifications-column-header-sort-wrapper {
  height: 22px;
  width: auto;
  cursor: pointer;
  color: #3d2541;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
  user-select: none;
}

.notifications-column-header-sort-arrows {
  margin-top: 2px;
}

.notifications-column-header-sort-title {
  margin-right: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  text-transform: capitalize;
}

.notification-counter-wrapper {
  position: relative;
  cursor: pointer;
  margin-right: 16px;
}

.notification-counter-wrapper svg {
  height: 32px;
  width: 32px;
}

.notification-counter-wrapper span {
  position: absolute;
  top: -10px;
  left: -10px;
  line-height: 100%;
  background: #810000;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.dev-create-notif {
  cursor: pointer;
  margin-right: 8px;
  font-size: 12px;
  display: inline-block;
  padding: 4px;
  background: #e600ff;
  line-height: 100%;
  border-radius: 20%;
}

.notifications-spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 100;
}

.notifications-spinner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notifications-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
}

.notifications-table-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notifications-table-row {
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: center;
}

.notification-table-row-border {
  border: 1px solid rgba(61, 37, 65, 0.2);
  box-shadow: 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 6px;
  margin-bottom: 14px;
}

.notifications-table-cell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.notifications-table-cell-created {
  box-sizing: border-box;
  min-width: 90px;
  width: 90px;
  flex: 90 1 auto;
}

.notifications-table-cell-status {
  box-sizing: border-box;
  min-width: 80px;
  width: 80px;
  flex: 80 1 auto;
}

.notifications-table-cell-message {
  box-sizing: border-box;
  min-width: 160px;
  width: 160px;
  flex: 160 1 auto;
}

.notifications-table-cell-version {
  box-sizing: border-box;
  min-width: 62px;
  width: 62px;
  flex: 62 1 auto;
}

.notifications-table-cell-customer {
  box-sizing: border-box;
  min-width: 85px;
  width: 85px;
  flex: 85 1 auto;
}

.notifications-table-cell-source {
  box-sizing: border-box;
  min-width: 80px;
  width: 80px;
  flex: 80 1 auto;
}

.notifications-table-cell-manage {
  box-sizing: border-box;
  min-width: 80px;
  width: 80px;
  flex: 80 1 auto;
}

.notifications-table-tooltip {
  color: #000000;
  border: 1px solid rgba(61, 37, 65, 0.2);
}

@media screen and (max-width: 480px) {
  .assessment-wrapper {
    padding-bottom: 130px;
  }
  .assessment-btn {
    bottom: 225px;
  }
  .assessment-field {
    flex-direction: column;
    gap: 10px;
  }
  .assessment-variants {
    flex-direction: column;
  }
  .new-order-price {
    font-size: 14px;
  }
  .custom-btn {
    min-width: 100%;
  }
  .back-link {
    left: 20px;
    font-size: 18px;
  }
  .title {
    font-size: 28px;
    line-height: 135%;
  }
  .descr {
    font-size: 14px;
    margin-top: 4px;
  }
  .dashboard-circle {
    transform: rotate(45deg) scale(0.7);
  }
}
